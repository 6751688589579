<template>
  <b-card class="development-scheduler">
    <vs-scheduler />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import VsScheduler from '@/components/ui/vs-scheduler/VsScheduler.vue';

export default {
  name: 'DevelopmentScheduler',
  components: {
    BCard,
    VsScheduler,
  },
};
</script>
